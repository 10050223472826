import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MainContainerComponent } from './feature/shared/components/main-container/main-container.component';
import { Store } from '@ngrx/store';
import { AUTH_DATA_ACTIONS } from './state/authentication/auth.actions';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    // 3rd
    RouterOutlet,
    // Project
    MainContainerComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private readonly store: Store,
  ) {
    this.store.dispatch(AUTH_DATA_ACTIONS.HydrateTokens());
  }
}
