import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { APP_EFFECTS, APP_REDUCERS, FEATURE_KEY } from './state';
import { DEV_MODULES, META_REDUCERS, STORE_CONFIG } from './state/dev-tools';
import { provideToastr } from 'ngx-toastr';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './feature/auth/services/auth-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideNgxErrorsConfig } from '@ngspot/ngx-errors';
import { ROUTES } from './app.routes';

export const APPCONFIG: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(ROUTES),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
    importProvidersFrom(
      StoreModule.forRoot({ router: routerReducer }, { metaReducers: META_REDUCERS, ...STORE_CONFIG }),
      BrowserAnimationsModule,
      EffectsModule.forRoot([]),
      StoreRouterConnectingModule.forRoot(),
      EffectsModule.forFeature(APP_EFFECTS),
      StoreModule.forFeature(FEATURE_KEY, APP_REDUCERS),
      ...DEV_MODULES,
    ),
    provideToastr({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    provideNgxErrorsConfig({
      showErrorsWhenInput: 'dirty',
      showMaxErrors: 1,
    }),
  ],
};
