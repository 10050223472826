import { ActionReducer, MetaReducer } from '@ngrx/store';
import { ENVIRONMENT } from '../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

const IS_DEV_ENVIRONMENT = ENVIRONMENT.name === 'DEV';

export const DEV_MODULES = IS_DEV_ENVIRONMENT
  ? [
      StoreDevtoolsModule.instrument({
        maxAge: 100,
        logOnly: false,
      }),
    ]
  : [];

export const STORE_CONFIG = IS_DEV_ENVIRONMENT
  ? {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }
  : {};

/* eslint-disable @typescript-eslint/no-explicit-any */
const debugState =
  (reducer: ActionReducer<unknown>): ActionReducer<any> =>
  (state, action) =>
    reducer(state, action);

export const META_REDUCERS: MetaReducer[] = [debugState];
