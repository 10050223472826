import { Routes } from '@angular/router';
import { authGuard } from './feature/auth/services/auth.guard';
import { loggedInGuard } from './feature/auth/services/logged-in.guard';
import { NAVIGATION_PATH } from './feature/shared/constants/navigation-paths'; /* eslint-disable id-length */

/* eslint-disable id-length */
export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: NAVIGATION_PATH.USERS,
    pathMatch: 'full',
  },
  {
    path: NAVIGATION_PATH.AGENCIES,
    loadComponent: () => import('./feature/agencies/agencies.component').then((c) => c.AgenciesComponent),
    canActivate: [authGuard],
  },
  {
    path: NAVIGATION_PATH.USERS,
    loadComponent: () => import('./feature/users/users.component').then((c) => c.UsersComponent),
    canActivate: [authGuard],
  },
  {
    path: NAVIGATION_PATH.BET_REGISTER,
    loadComponent: () => import('./feature/bet-register/bet-register.component').then((c) => c.BetRegisterComponent),
    canActivate: [authGuard],
  },
  {
    path: NAVIGATION_PATH.TRANSACTIONS,
    loadComponent: () => import('./feature/transactions/transactions.component').then((c) => c.TransactionsComponent),
    canActivate: [authGuard],
  },
  {
    path: NAVIGATION_PATH.LOGIN,
    loadComponent: () => import('./feature/auth/login/login.component').then((c) => c.LoginComponent),
    canActivate: [loggedInGuard],
  },
];
